<template>
  <div v-if="processedBanners.length" class="banners-place">
    <template v-for="(banner, i) of processedBanners">
      <a v-if="banner.url" :key="i" :href="banner.url" target="_blank" rel="noreferrer noopener">
        <v-img :class="{ 'mt-3': i }" :src="banner.src" height="auto" eager contain />
      </a>

      <v-img v-else :key="i" :class="{ 'mt-3': i }" :src="banner.src" height="auto" eager contain />
    </template>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { getBannerImageUrl } from '@/views/BannersManagementView/bannersManagement'

  function processBanners(banners) {
    return banners.map((banner) => ({
      ...banner,
      src: getBannerImageUrl(banner.filename)
    }))
  }

  export default {
    name: 'BannersPlace',

    props: {
      placeType: {
        type: String,
        required: true
      },
      places: {
        type: Array,
        default: () => []
      },
      load: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        fetchedPlaces: []
      }
    },

    computed: {
      placesIndex() {
        const places = this.load ? this.fetchedPlaces : this.places
        return _.keyBy(places, 'type')
      },

      processedBanners() {
        const place = this.placesIndex[this.placeType]
        if (!place) {
          return []
        }

        return processBanners(place.banners)
      }
    },

    created() {
      if (this.load) {
        this.fetchBanners()
      }
    },

    methods: {
      async fetchBanners() {
        this.fetchedPlaces = await this.$api.backend.banners.get_banner_places()
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
