<template>
  <page-container class="faq fill-height" :title="$t('pages.titles.faq')">
    <div class="d-flex">
      <v-sheet class="faq-section flex-grow-1">
        <div class="faq-section__title">{{ selectedSection.title }}</div>

        <v-expansion-panels :key="selectedSection.title" class="mt-8" accordion multiple flat>
          <v-expansion-panel v-for="(item, i) of selectedSection.items" :key="i">
            <v-expansion-panel-header class="font-weight-bold">
              {{ item.question }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <component :is="item.answer" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>

      <v-tabs v-model="selectedSectionIndex" class="flex-grow-0 align-self-start ml-10" vertical>
        <v-tab v-for="(section, i) of faqConfiguration" :key="i">
          {{ section.title }}
        </v-tab>
      </v-tabs>
    </div>

    <v-spacer />

    <banners-place class="mt-10" :place-type="BannerPlaceType.FAQ_ADVERTISING" load />
  </page-container>
</template>

<script>
  import { BannerPlaceType } from '@/enums/aquarius'
  import BannersPlace from '@/components/BannersPlace'
  import { faqConfiguration } from '@/views/FaqView/faq'

  export default {
    name: 'FaqView',
    components: { BannersPlace },

    data() {
      return {
        BannerPlaceType,

        faqConfiguration,
        selectedSectionIndex: 0
      }
    },

    computed: {
      selectedSection() {
        return this.faqConfiguration[this.selectedSectionIndex]
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .faq {
    .faq-section {
      padding: 28px 80px;

      .faq-section__title {
        font-size: 26px;
        line-height: 30px;
        font-weight: bold;
      }

      .v-expansion-panels {
        &::v-deep {
          .v-expansion-panel:not(:first-child) {
            border-top: thin solid map-get($material-theme, 'dividers');
          }
        }
      }
    }

    .v-tabs {
      position: sticky;
      top: 72px;
      width: min-content;

      .v-tab {
        justify-content: flex-start;
      }
    }
  }
</style>
