import _ from 'lodash'

const tagNames = ['ul', 'ol', 'li']
function answerHelper(config) {
  if (_.isString(config)) {
    return {
      render: (h) => h('div', config)
    }
  } else if (Array.isArray(config)) {
    return {
      render: (h) =>
        h(
          'div',
          config.map((item) => h('p', item))
        )
    }
  }

  return {
    render: (h) => {
      const helpers = tagNames.reduce((acc, tag) => {
        acc[tag] = _.partial(h, tag)
        return acc
      }, {})
      helpers.createLink = (href, text = undefined) =>
        h('a', { attrs: { href, target: '_blank', rel: 'noreferrer noopener' } }, text ?? href)

      let result = config(helpers)

      if (Array.isArray(result)) {
        result = result.map((el) => {
          if (Array.isArray(el)) {
            return h(
              'p',
              el.map((subEl) => (_.isString(subEl) ? { text: subEl } : subEl))
            )
          } else if (_.isString(el)) {
            return h('p', el)
          } else {
            return el
          }
        })
        return h('div', result)
      } else {
        return result
      }
    }
  }
}

export const faqConfiguration = [
  {
    title: 'Общие',
    items: [
      {
        question: 'Для чего нужен интернет-сервис?',
        answer: [
          'Интернет-сервис - визуализирует доступные морские акватории для разведения аквакультуры и позволяет удалённо подавать заявление на определение границ рыбоводного участка (далее - РВУ) в нужных инвестору координатах, получать актуальную информацию о РВУ, а также принимать участие в торгах в электронной форме на право заключения договора пользования РВУ на ЭТП РТС-Тендер. Данный сервис разработан в рамках выполнения указаний Президента и Правительства по ускоренному вводу в экономический оборот акваторий дальневосточных морей путём устранения избыточных административных барьеров.',
          'В настоящее время интерактивная карта является уникальным в России продуктом, созданным благодаря проведённой масштабной работе по сбору, анализу, обработке и оцифровке многочисленных запретов и ограничений, введённых государственными органами различного уровня в отношении акватории дальневосточных морей. По результатам анализа более 360 млн гектар акватории установлено, что только в отношении менее 70 млн гектар не имеется каких-либо обременений. Таким образом, поскольку запретные зоны заранее определены, а инвестору предоставлено право четырьмя нажатиями левой кнопкой мыши на интерактивной карте определить границы рыбоводного участка, исходя из собственных потребностей, никаких дополнительных межведомственных согласований при издании правового акта Росрыболовства об установлении границ РВУ уже не потребуется.',
          'Процесс подготовки и направления заявления от инвестора в Росрыболовство полностью прозрачен и автоматизирован благодаря использованию заранее установленной формы, которую заявители заверяют электронной цифровой подписью'
        ]
      },
      {
        question:
          'Сколько длится процедура получения права пользования рыбоводных участков и из каких этапов она состоит?',
        answer: ({ ul, li }) => [
          'Вся процедура оформления прав на пользование РВУ занимает не более 80 дней, начиная с момента направления заявления в Росрыболовство, издания соответствующего ведомственного акта, подготовки и проведения электронных аукционов, и заканчивая заключением соответствующего договора на пользование участком (также оформляется в электронной форме). Однако реальное время процедуры зависит от того, с какого этапа пользователь принял участие:',
          ul([
            li(
              'Подготовительный этап: регистрация на ЭТП РТС-Тендер, регистрация в интернет-сервисе "Аквавосток", получение ЭЦП - 1-3 рабочих дней'
            ),
            li(
              'Формирование РВУ и проверка в Росрыболовстве: формирование границ РВУ, подача заявления, получение решения об определении участка, объявление торгов - 10 рабочих дней'
            ),
            li('Сбор заявок на участие в торгах и оплата задатков - 30 рабочих дней'),
            li('Анализ заявок, допуск участников и подготовка к торгам - 5 рабочих дней.'),
            li('Проведение торгов и выбор победителя - 3 рабочих дня.'),
            li('Перечисление доплаты и подписание договора - 30 рабочих дней.')
          ]),
          'В будущем планируется увеличение количества рыбоводных хозяйственных бассейнов (РХБ) на сервисе для реформирования условий «входа» в отрасль и увеличения прозрачности процедур благодаря внедрению современных цифровых технологий.'
        ]
      },
      {
        question: 'Возможны ли сбои в работе интернет-сервиса и насколько оперативно они устраняются?',
        answer: [
          'Сбои возможны в работе любого программного обеспечения. Они могут связаны с системными ошибками, некорректными действиями пользователя, нестабильностью каналов связи.',
          'Мониторинг работоспособности интернет-сервиса осуществляется группой специалистов. В случае выявления каких-либо проблем их анализ и выработка решений занимает не более 24 часов. В ряде сложных случаев может потребоваться 2-3 дня на тестирование работоспособности принимаемых решений и их внедрение.'
        ]
      },
      {
        question: 'Могут ли физические лица заниматься арендой рыбоводных участков?',
        answer:
          'Нет, сервис предоставляет свои услуги только для юридических лиц, зарегистрированных на территории Российской Федерации.'
      },
      {
        question: 'Какой срок аренды рыбоводных участков?',
        answer: 'Стандартный срок аренды рыбоводных участков составляет 25 лет.'
      }
    ]
  },

  {
    title: 'Юридические',
    items: [
      {
        question: 'Каким документом определен порядок формирования рыбоводных участков?',
        answer: [
          'Порядок формирования рыбоводных участков (далее - РВУ) определен постановлением Правительства от 9 апреля 2018 года № 429, установившим заявительный порядок создания марикультурных участков взамен действовавшего ранее разрешительного порядка и передавшим инициативу создания РВУ от госоргана непосредственно инвестору.',
          'Границы РВУ, согласно установленным в постановлении Правительства от 11 ноября 2014 г. № 1183 требованиям, можно создать четырьмя последовательными нажатиями левой кнопкой мыши на точки на интерактивной карте, определив тем самым угловые координаты фигуры, которые будут соединяться четырьмя прямыми непересекающимися линиями либо по береговой линии.'
        ]
      },
      {
        question: 'Какими документами регламентируются торги?',
        answer: [
          'Правила организации и проведения торгов в электронной форме на право заключения договора пользования рыбоводным участком установлены постановлением Правительства от 15 мая 2014 г. №450 и регламентом соответствующей электронной площадки.',
          'Торги проходят на аккредитованных электронных площадках, указанных в перечне юридических лиц для организации продажи государственного и муниципального имущества в электронной форме, утвержденном распоряжением Правительства Российской Федерации от 4 декабря 2015 г. № 2488-р. В настоящее время подключение к интерактивной карте Фонда имеет только ООО «РТС-Тендер»'
        ]
      },
      {
        question: 'Как обеспечивается безопасность мореплавания в пределах рыбоводных участков?',
        answer: [
          'В соответствии с требованиями законодательства и, в частности, положений Федерального закона «О навигационной деятельности», порядка определения границ рыбоводных участков и других, Росрыболовство в течение 3 дней направляет в Минтранс России и Минобороны России подписанные договоры пользования рыбоводными участками. Указанные ведомства в установленном порядке принимают решение об определении режимов плавания в границах акваторий таких участков и уведомляют мореплавателей через действующую систему извещений, а также размещают соответствующую информацию на государственных морских навигационных картах.',
          'Для прохода судов и иных плавательных средств между участками устанавливается буферная зона в размере 500 метров для ДВРХБ и 5000 метров для СРХБ.'
        ]
      },
      {
        question: 'Почему и на основании чего изменяются зоны доступной акватории?',
        answer: [
          'Закон «О навигации» разрешает вводить соответствующие ограничения в случае проведения в морских акваториях мероприятий, влияющих на безопасность судоходства. На этом основании возможно изменение границ рыболовных и рыбоводных участков, судоходных путей и подходов к портам, требований ООПТ и пр.',
          'В принятом Правительством постановлении от 9 апреля 2018 г. № 429 установлен порядок формирования и поддержания информационных баз в актуальном состоянии. Все изменения перечней доступных акваторий публикуются на официальном сайте Минвостокразвития на основании мониторинга изменений нормативно-правовых актов федеральных и региональных органов власти и государственной морской навигационной карты.'
        ]
      }
    ]
  },

  {
    title: 'Регистрация',
    items: [
      {
        question: 'Как получить электронную цифровую подпись?',
        answer: ({ createLink }) => [
          'Для целей аквакультуры должна использоваться усиленная квалифицированная электронная подпись (УКЭП), получить которую можно в любом удостоверяющем центре, аккредитованном Минкомсвязи России.',
          [
            'Также УКЭП можно получить в удостоверяющем центре оператора ЭП по адресу: ',
            createLink('https://www.rts-tender.ru/digital-signature')
          ]
        ]
      },
      {
        question: 'Зачем привязывать свой профиль к учетной записи РТС-тендер?',
        answer:
          'При подаче заявления в Росрыболовство на формирование границ рыбоводного участка (далее - РВУ) данные передаются в систему ЭТП РТС-тендер для формирования необходимой для создания конкурса документации. Без привязки профиля к учётной записи ЭТП РТС-тендер подача заявления невозможна. Привязка профиля к учётной записи РТС-тендер является полностью безопасной процедурой. Интернет-система не осуществляет никакие действия на ЭТП РТС-тендер, кроме подачи заявок по формированию границ РВУ, а также не получает данные о ваших торгах.'
      },
      {
        question: 'Что случится, если я отвяжу свой профиль от учетной записи РТС-тендер?',
        answer:
          'Функция открепления профиля от учётной записи РТС-тендер необходима для того,чтобы в случае изменения учетной записи РТС-тендер иметь возможность привязки профиля к новой учётной записи РТС-тендер. Пользователи, у которых откреплена учётная запись РТС-тендер от профиля, не могут подавать заявления в Росрыболовство на формирование границ рыбоводного участка.'
      },
      {
        question: 'Что делать, если не приходит письмо для подтверждения регистрации?',
        answer: ({ ol, li }) =>
          ol([
            li(
              'Проверьте папку "Спам" (нежелательная почта) в вашем почтовом ящике или почтовой программе. Иногда наши письма могут попасть туда.'
            ),
            li(
              'Проверьте правильность введённого адреса электронной почты. Возможно, вы ошиблись при регистрации и указали неправильный адрес. В таком случае следует зарегистрироваться, указав правильный e-mail.'
            ),
            li(
              'Нажмите кнопку "отправить письмо ещё раз" и снова проверьте наличие письма в почтовом ящике или почтовой программе, а также в папке "Спам".'
            ),
            li(
              'Если после вышеуказанных действий вы не получили письмо и сайт при входе в личный кабинет выдаёт ошибку "Для работы с данным разделом необходимо подтвердить почту", то воспользуйтесь функцией сброса пароля - вам будет выслано новое письмо для подтверждения регистрации.'
            )
          ])
      }
    ]
  },

  {
    title: 'Подача заявления',
    items: [
      {
        question: 'Что такое буферная зона?',
        answer:
          'Буферная зона - это участок акватории, созданный для прохода судов и иных плавательных средств между РВУ. Размер буферной зоны вокруг РВУ составляет 500 метров для Дальневосточного рыбохозяйственного бассейна и 5000 метров для Северного рыбохозяйственного бассейна. Создание РВУ, расположение которого пересекается с буферной зоной другого РВУ, - запрещено. Однако буферные зоны соседних РВУ могут пересекаться между собой.'
      },
      {
        question: 'Почему определённые участки морских акваторий недоступны для аквакультуры?',
        answer: [
          'При формировании карты доступных для ведения рыбоводства акваторий с целью соблюдения требований национальной безопасности был применён согласованный с соответствующими органами квадратно-гнездовой метод обработки информации об ограничениях (обременениях) в отношении той или иной акватории. Такой подход позволил, с одной стороны, обезличить установленные государством ограничения, но, с другой стороны, привёл к увеличению площади недоступной акватории.',
          'В качестве источников информации о координатах введённых запретов, ограничений и обременений в отношении акваторий использовались правовые акты органов исполнительной власти, органов власти прибрежных регионов, учитывалось мнение профильных научных учреждений (в частности – ТИНРО, при анализе устьев анадромных рек).'
        ]
      },
      {
        question: 'Почему и на основании чего изменяются зоны доступной акватории?',
        answer: [
          'Закон «О навигации» разрешает вводить соответствующие ограничения в случае проведения в морских акваториях мероприятий, влияющих на безопасность судоходства. На этом основании возможно изменение границ рыболовных и рыбоводных участков, судоходных путей и подходов к портам, требований ООПТ и пр.',
          'В принятом Правительством постановлении от 9 апреля 2018 г. № 429 установлен порядок формирования и поддержания информационных баз в актуальном состоянии. Все изменения перечней доступных акваторий публикуются на официальном сайте Минвостокразвития на основании мониторинга изменений нормативно-правовых актов федеральных и региональных органов власти и государственной морской навигационной карты.'
        ]
      }
    ]
  },

  {
    title: 'Торги',
    items: [
      {
        question: 'Что необходимо для участия в торгах?',
        answer: ({ ul, li }) => [
          'Для участия в торгах необходимо:',
          ul([
            li('Устойчивое соединение с сетью Интернет;'),
            li(
              'Усиленная квалифицированная электронная подпись, выданная удостоверяющим центром, аккредитованным в установленном порядке Минкомсвязи России;'
            ),
            li('Аккредитация на электронной площадке РТС-тендер')
          ])
        ]
      },
      {
        question: 'Как проходят электронные торги?',
        answer: ({ ol, li, createLink }) => [
          'Правила организации и проведения аукционов в электронной форме на право заключения договора пользования рыбоводным участком (далее - РВУ) установлены постановлением Правительства от 15 мая 2014 г. №450 и регламентом соответствующей электронной торговой площадки (далее - ЭТП).',
          [
            'Информация о планируемых аукционах в электронной форме на право заключения договора пользования РВУ в обязательном порядке размещается на ',
            createLink('https://torgi.gov.ru/new/public', 'официальном сайте государственных торгов'),
            ', а также на витринах торгов на ',
            createLink('https://fish.rts-tender.ru/?fl=True', 'сайте Росрыболовства'),
            ' и на соответствующей витрине торгов ',
            createLink('https://torgi.rts-tender.ru', 'на сайте электронной площадки ООО «РТС-Тендер»'),
            '.'
          ],
          'Для участия в электронных торгах необходимо подать заявку на участие в выбранном аукционе через рабочий кабинет ЭТП РТС-тендер (независимо от того, был ли инвестор инициатором создания соответствующего РВУ или нет). По результатам рассмотрения Росрыболовством поданной заявки на участие в электронных торгах инвестор получит соответствующее уведомление в своем рабочем кабинете на ЭТП РТС-тендер.',
          'Инвестору, при направлении заявки на участие в электронных торгах, необходимо также оплатить задаток в установленном документацией размере. Как правило, это происходит следующим образом: инвестор заранее (с учётом сроков осуществления банковских платежей) вносит на свой лицевой счет, открытый на электронной площадке, денежную сумму равную или превышающую размер установленного задатка. При получении заявки на участие в торгах оператор электронной площадки блокирует на лицевом счёте инвестора денежную сумму, равную размеру задатка соответствующих торгов.',
          'Необходимо отметить, что для предотвращения нежелательных действий в виде недобросовестной конкуренции список допущенных к торгам участников не публикуется, названия компаний, участвующих в процессе торгов, не раскрываются. Инвестор принимает участие в торгах через свой рабочий кабинет на ЭТП РТС-тендер, подавая ценовые предложения согласно установленным ценовым размерам одного шага торгов. На один шаг торгов максимально отводится 10 минут. Это означает, что вслучае отсутствия ценовых предложений после последнего шага в течение 10 минут торги прекращаются, а победителем признается инвестор, который последним подал ценовое предложение.',
          'По результатам подведения итогов торгов инвесторы-участники торгов получают в своих рабочих кабинетах на ЭТП РТС-тендер извещения о результатах аукциона. Через 10 дней Росрыболовство направляет победителю для подписания проект договора пользования соответствующим РВУ . Победитель должен подписать полученный договор в течение 10 дней.',
          'В случае уклонения победителя от подписания направленного Росрыболовством проекта договора пользования РВУ Росрыболовство предложит подписать аналогичный договор тому участнику торгов, который подал предпоследнее ценовое предложение. При этом, согласно установленным Правилам, лицу, уклонившемуся от подписания договора пользования РВУ, задаток не возвращается и перечисляется в доход бюджетной системы.',
          'Настоятельно рекомендуем ознакомиться с инструкциями, описывающими работу на электронной площадке, по ссылкам:',
          ol([
            li([createLink('http://help.rts-tender.ru/articles/list?id=677')]),
            li([createLink('http://help.rts-tender.ru/articles/list?id=682')]),
            li([createLink('http://help.rts-tender.ru/articles/list?id=1017')]),
            li([createLink('http://help.rts-tender.ru/articles/list?id=688')]),
            li([createLink('https://www.rts-tender.ru/trainings/webinars')])
          ])
        ]
      },
      {
        question:
          'Возможны ли сбои при проведении электронных торгов и какие действия необходимо предпринять в этом случае пользователю интернет-сервиса?',
        answer: [
          'Сбои возможны в работе любого программного обеспечения. Они могут связаны с системными ошибками, некорректными действиями пользователя, нестабильностью каналов связи и электрических сетей.',
          'Если пользователь полагает, что в ходе проведения торгов сценарий торгов выполнялся с нарушением, он может обратиться в службу техподдержки по телефону +7 (499) 653-77-00 в будние дни с 5.00 до 19.00 по московскому времени, описать суть обращения и попросить проверить корректность работы программного обеспечения с последующей выдачей официального ответа по такому обращению.'
        ]
      }
    ]
  }
]
faqConfiguration.forEach((section) => {
  section.items.forEach((item) => {
    item.answer = answerHelper(item.answer)
  })
})
